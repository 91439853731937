<template>
    <div class="ban-list pd-10 h-calc-60">
        <div class="forms-content">
            <div class="add-and-del">
                <el-button type="primary" @click="toAdd">添加敏感词</el-button>
                <el-button type="danger" @click="del">批量删除</el-button>
            </div>
            <el-input v-model.trim="searchValue" placeholder="输入敏感词" clearable style="width: 300px;" 
                @clear="lists"
                @keydown.enter.native="lists"
                >
                <template #append><el-button :icon="Search" @click="lists"  /></template>
            </el-input>
        </div>
        <div class="words-list">
            <el-table :data="tableData" style="width: 100%;" height="calc(100% - 50px)"  @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" />
                <el-table-column label="序号" width="55" align="center">
                    <template #default="scope">
                        {{sortIndex(scope.$index)}}
                    </template>
                </el-table-column>
                <el-table-column prop="word" label="敏感词" align="center" width="300" />
                <el-table-column label="操作" align="center">
                    <template #default="scope">
                        <el-button type="danger" @click="del(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <PageComponent :style="pageStyle" :page="page" :limit="limit" :total="total" @change="pageChange" />
        </div>
    </div>
    <el-dialog v-model="dialogVisible" title="添加敏感词" width="30%" @close="closeDialog(formRef)">
        <el-form :model="form" :rules="rules" ref="formRef">
            <el-form-item label="敏感词" prop="value">
                <el-input v-model="form.value" @keydown.enter.native="makeAdd(formRef)" />
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="btn-group">
                <el-button type="danger" @click="dialogVisible=false">取消</el-button>
                <el-button type="primary" @click="makeAdd(formRef)">确定</el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script setup>
import { ref, onMounted, reactive } from 'vue';
import { Search } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router';
import {wordsList, wordDel, wordAdd} from "@/utils/apis.js"
import { ElMessageBox } from 'element-plus/lib/components';
import { ElMessage } from 'element-plus';
import PageComponent from '../../../components/common/PageComponent.vue';
const router = useRouter();
const searchValue = ref('')
const tableData = ref([])
const page = ref(1)
const limit = ref(10)
const total = ref(0)
const selected = ref([])
const dialogVisible = ref(false)
const formRef = ref()
const form =  reactive({value: ''})
const rules = reactive({
        value: [
            {required: true, message: '敏感词不为空', trigger: 'blur'}
        ]
    })
const pageChange = (value) => {
    page.value = value
    lists()
}
const pageStyle = ref('margin-top: 10px;')
const del = (row= null) => {
    if (!selected.value.length && !row.word_id) {
        return ElMessage.warning('请选择要删除的敏感词')
    }
    ElMessageBox.confirm(
        '确定要删除么？删除之后则无法恢复',
        '删除敏感词',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }
    ).then(() => {
        const formdata = row.word_id ? {word_ids: row.word_id} : {word_ids: selected.value.join()}
        deleteWords(formdata)
    }).catch(err => {
        ElMessage.info('已取消')
        console.log('err', err);
    })
}
const deleteWords = async (formData) => {
    try {
        const {data, msg} = await wordDel(formData)
        ElMessage({
            type: 'success',
            message: msg,
            duration: 2000,
            onClose: () => {
                lists()
            }
        })
    } catch (err) {
        ElMessage.error(err.message)
    }
}
const sortIndex = (index) => {
    return index + 1 + (limit.value * (page.value - 1))
}
const toAdd = () => {
    dialogVisible.value = true;
}

const makeAdd = async (formEle) => {
    console.log(formEle)
    if (!formEle) return
    await formEle.validate((valid, fields) => {
        if (valid) {
            wordsAdd()
        } else {
            console.log('error submit!', fields);
        }
    })
}
const wordsAdd = async () => {
    console.log(0)
    const formData = {
        word: form.value
    }
    try {
        const {data, msg} = await wordAdd(formData)
        ElMessage({
            type: 'success',
            message: msg,
            duration: 1500,
            onClose: () => {
                dialogVisible.value = false
                lists()
            }
        })
    } catch (err) {
        ElMessage.error(err.message)
    }
}
const closeDialog = (formEle) => {
    formEle.resetFields()
}

const lists = async () => {
    let params = {
        paging: 1,
        page: page.value,
        pageSize: limit.value,
    }
    if (searchValue.value) {
        params['like'] = searchValue.value
    }
    const {data, msg} = await wordsList(params)
    total.value = data.total
    tableData.value = data.list
}
const handleSelectionChange = (val) => {
    selected.value = val.map((item) => {
        return item.word_id
    })
}
onMounted(() => {
    lists()
})
</script>
<style scoped lang="scss">
.ban-list {
    &, & * {
        box-sizing: border-box;
    }
    .forms-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .add-and-del {
            padding-left: 10px;
        }
    }
    .words-list {
        margin-top: 20px;
        height: calc(100% - 62px);
    }
}
</style>